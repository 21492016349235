import { PricingPlanTypes } from "@finq/pricing/types"

export const useUserWaitingList = () => {
  const user = useUser()
  const notifyMe = useNotifyMe()

  const isPlanOnWaitingList = reactify((p: MaybeRef<PricingPlanWithDetails>) => {
    const plan = toRef(p)

    if (!user.isAuthenticated.value) return false

    /**
     * Checks the plan's pricing status is `WAITING_LIST`
     */
    const isInWaitingListStatus =
      plan.value?.pricing?.pricingPlanStatus === PricingPlanTypes.UserPlanStatus.WAITING_LIST

    /**
     * Checks if the plan exists in the user's waiting list.
     */
    const isPlanInUserWaitingList = user.user.value?.waitingList.some((waitingItem) => {
      return waitingItem.productId === plan.value?.productId && waitingItem.planCode === plan.value?.code
    })

    /**
     * Checks if the plan was recently added to the waiting list.
     * This is an important indicator to show the user that the plan was added successfully to the waiting list and reflect it in the UI.
     */
    const isPlanRecentlyAddedToWaitingList = notifyMe.recentlyAddedWaitingList.value.some((waitingItem) => {
      return waitingItem.productId === plan.value?.productId && waitingItem.code === plan.value?.code
    })

    return (
      (plan.value?.planCode && isPlanRecentlyAddedToWaitingList) ||
      isInWaitingListStatus ||
      isPlanInUserWaitingList
    )
  })

  return {
    isPlanOnWaitingList,
  }
}
