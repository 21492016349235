<template>
  <div class="flex w-full flex-1 flex-col py-10" v-auto-animate="autoAnimateFade">
    <!-- Error -->
    <div class="flex flex-col items-center justify-center text-center text-black/75" v-if="isError">
      <h2 class="mb-xxxs text-2.5xl font-semibold">{{ t("error.title") }}</h2>
      <span class="text-steelgray whitespace-pre-line">{{ t("error.message") }}</span>

      <UiButton class="mt-xs md:h-10 md:w-full" @click="emit('close')" variant="link">
        {{ backText }}
      </UiButton>
    </div>

    <!-- Main -->
    <template v-else>
      <div
        v-if="state.step === 'default'"
        :class="
          cn('min-md:text-sm flex flex-col items-center justify-center gap-4', { 'animate-pulse': isLoading })
        "
      >
        <span> {{ $t("stay_tuned") }}</span>
        <h3 class="text-2.5xl font-semibold">{{ plan?.title }}</h3>
        <i18n-t
          class="whitespace-pre-line text-center font-light text-black/50"
          tag="span"
          keypath="your_email"
        >
          <template #email>
            <span class="text-primary font-medium">{{ user.user.value?.email }}</span>
          </template>
        </i18n-t>

        <UiButton
          class="mt-xs w-32 md:h-10 md:w-full"
          data-action="waitlist"
          @click="onSubmit"
          :loading="isLoading"
        >
          {{ $t("signup") }}
        </UiButton>
      </div>

      <!-- Confirmation -->
      <div
        class="min-md:text-sm flex flex-col items-center justify-center gap-4"
        v-else-if="state.step === 'confirmation'"
      >
        <h3 class="text-2.5xl font-semibold">{{ $t("confirmation.title") }}</h3>
        <i18n-t
          class="whitespace-pre-line text-center font-light text-black/75"
          tag="span"
          keypath="confirmation.message"
        >
          <template #planName>
            <span class="text-primary font-medium">{{ plan?.title }}</span>
          </template>
        </i18n-t>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
interface NotifyMeState {
  status: "idle" | "loading" | "success" | "error"
  step: "default" | "confirmation"
}

const { t } = useI18n()
const { t: $t } = useI18n({ useScope: "local" })
const route = useRoute()
const user = useUser()
const emit = defineEmits(["close", "success"])

const props = defineProps<{
  callbacks?: NotifyMeCallbacks
  plan?: PricingPlanWithDetails
}>()

const state = reactive<NotifyMeState>({ status: "idle", step: "default" })
const isLoading = computed(() => state.status === "loading")
const isError = computed(() => state.status === "error")
const backText = computed(() => {
  return stripRouteNameSuffix(route.name) === "index" ? $t("confirmation.cta") : t("common.back")
})

const currentRouteName = computed(() => stripRouteNameSuffix(route.name))

const onSubmit = async (e: Event) => {
  state.status = "loading"

  try {
    await UserService.notifyMe({
      email: user.user.value?.email!,
      productId: props.plan?.productId!,
      productPlanCode: props.plan?.code!,
      productPlanPricingType: props.plan?.pricing?.type!,
    })

    props.callbacks?.success?.()
    emit("success")

    state.status = "success"
    state.step = "confirmation"
  } catch (e) {
    state.status = "error"
  }
}
</script>

<i18n lang="json">
{
  "en": {
    "stay_tuned": "Stay informed",
    "your_email": "You'll be notified at this email:\n{email}",
    "signup": "Submit",
    "confirmation": {
      "title": "We’ll be in touch soon!",
      "message": "Thank you for taking interest in our future developments.\nYou’ve been added to the {planName} waiting list.\n\nMeanwhile, you can continue exploring our live products.",
      "cta": "Back to homepage"
    },
    "error": {
      "title": "Oops, something went wrong",
      "message": "Your request could not be processed.\nPlease try again later."
    }
  },
  "he": {
    "stay_tuned": "הישאר/י מעודכנ/ת",
    "your_email": "תקבל/י עדכון במייל:\n{email}",
    "signup": "הרשמה",
    "confirmation": {
      "title": "נהיה בקשר בקרוב!",
      "message": "תודה שהתעניינת בהתפתחויות העתידיות שלנו.\nנכנסת לרשימת ההמתנה של {planName}.\n\nבינתיים את/ה מוזמנ/ת להמשיך ולחקור את המוצרים שלנו.",
      "cta": "חזרה לדף הבית"
    },
    "error": {
      "title": "אופס, משהו השתבש",
      "message": "לא ניתן היה לעבד את הבקשה שלך.\nאנא נסה שוב מאוחר יותר."
    }
  }
}
</i18n>
