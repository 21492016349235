import { NotifyMe } from "#components"

export interface NotifyMeCallbacks {
  success?: () => void
  close?: () => void
}
export const recentlyAddedWaitingList = ref<Partial<PricingPlanWithDetails>[]>([])
export const useNotifyMe = () => {
  const gtm = useGtm()
  const popup = usePopup()
  const router = useRouterUtils()
  const defaultCallbacks: NotifyMeCallbacks = reactive({
    success() {},
    close() {},
  })

  function onClose(callbacks?: NotifyMeCallbacks) {
    const { route, replace } = router

    if (!isNullOrUndefined(route.value.query?.notifyme)) {
      replace({ name: stripRouteNameSuffix(route.value.name), params: route.value.params })
    }

    popup.close()
    callbacks?.close?.()
  }

  const open = (data: MaybeRef<Partial<PricingPlanWithDetails>>, callbacks?: NotifyMeCallbacks) => {
    const plan = toRef(data)
    const mergedCallbacks = Object.assign({}, defaultCallbacks, callbacks)

    popup.open({
      type: "none",
      classes: {
        modal: { panel: "min-md:p-6" },
        closeButton: "p-0",
        root: "md:border-0",
        title: "hidden",
        body: "items-start p-0",
      },
      component: shallowRef(NotifyMe),
      componentData: {
        plan,
        callbacks: mergedCallbacks,
        onClose: () => onClose(mergedCallbacks),
        onSuccess: () => {
          recentlyAddedWaitingList.value = [...recentlyAddedWaitingList.value, plan.value]
        },
      },
      close: () => onClose(mergedCallbacks),
    })

    gtm.push({
      event: "notify_me_open",
      product_type: plan.value.productId,
      subscription_type: plan.value?.code!,
      period: plan.value.pricing?.type!,
    })
  }

  return { open, recentlyAddedWaitingList }
}
